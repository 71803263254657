.AppHeader{
  height: 550px;
  background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url("assets/email-pattern.png") repeat center;
  width: 100%;
}

.HeaderContainer{
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.TopBar{
  display: flex;
  justify-content: space-between;
}

.TopLeft{
  display: flex;
  padding-top: 20px; 
}

.TopRight{
  display: flex;
  padding-top: 50px; 
}

.TopRight a{
  font-size: 30px;
  color: white;
  padding-left: 20px;
  -webkit-transition: color 0.2s; 
  transition: color 0.2s;
}

.TopRight a:hover{
  color: #0097E6;
}


.Introduction{
  padding-top: 16px;
  margin-left: 30px;
  color: white;
}

.ProgrammingLanguages{
  text-align: center;
  color: white;
  margin-top: 120px;
  font-size: 30px;
}

.Me{
  -webkit-transition: transform 0.6s; 
  transition: transform 0.6s;
  height: 100px;
}

.Me:hover{
  transform: rotate(360deg);
}

input:focus {
  outline:0;
}

/* Search Section */
.SearchBox{
  width: 1000px;
  background-color: #0097E6;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -60px;
  display: flex;
}

.inputContainer{
  width: 85%;
}

.SearchInput{
  height: 70px;
  margin: 15px 0px 0px 20px;
  border-radius: 20px;
  width: 100%;
  border: none;
  font-size:25px;
  padding-left: 20px;
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
}

.searchButton{
  width: 140px;
  color: black;
  background-color: Transparent;
  border: 0px;
}

.fa-search{
  font-size: 30px;
  background-color: white;
  margin-left: 60px;
  width: 70px;
  text-align: center;
  height: 50px;
  padding-top: 20px;
  border-radius: 80px;
  color: black;
}

.fa-search:hover{
  color: #0097E6;
  -webkit-transition: color 0.2s; /* Safari */
  transition: color 0.2s;
}


.results{
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.result{
  width: 31.7%;
  text-align: center;
  height: 300px;
  margin: 0px 10px 50px 10px;
  border-radius: 20px;
  background-size: cover !important;
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  color: white;
  -webkit-transition: border-radius 0.2s; /* Safari */
  transition: border-radius 0.2s;
}

.result:hover{
  border-radius: 0px;
}

.languageContainer{
  display: flex;
  margin: 100px 0px 50px 0px;
  justify-content: space-evenly;
  background-color: #0097E6;
  height: 40px;
}

.individualLanguage{
  padding-top: 10px;
}

/* Popup CSS */
.hidePopup{
  display: none;
}

.Overlay{
  position: fixed; 
  width: 100%; 
  height: 100%; 
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2;
  cursor: pointer; 
}

.Popup{
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  width: 800px;
  display: flex;
}

.PopupSplit{
  width: 48%;
  margin-left: auto;
  margin-right: auto;
}

.PopupSplit:first-child{
  text-align: center;
}

.Popup img{
  width: 95%;
}

.Popup p{
  line-height: 22px;
  letter-spacing: 0.2px;
}

.Popup button{
  background-color: #0097E6;
  padding: 10px;
  border: 0px;
  color: white;
  width: 100%;
  font-size: 16px;
  border-radius: 20px;
  -webkit-transition: border-radius 0.5s; /* Safari */
  transition: border-radius 0.5s;
}

.Popup button:hover{
  border-radius: 0px;
}

.Popup button a{
  color: white;
  text-decoration: none;
}

/* Testimonial Section */
.jobTitle{
  margin: 20px;
  font-size: 35px;
  height: 100px;
}

.TestimonalContainer{
  width: 100%;
  background-color: #333333;
}

.TestimonialsCenter{
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
}

.Person{
  padding-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}

.Paul{
  border-radius: 20px;
  width: 100px;
  height: 100px;
}

.PersonText{
  width: 800px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-bottom: 10px;
  letter-spacing: 1px;
  line-height: 25px;
}

.TestimonialsCenter h4{
  color: white;
  text-align: center;
  padding-bottom: 20px;
}


/* Experience Section */
.ExperienceFull{
  width: 100%;
}

.ExpereinceContainer{
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: space-evenly;
}

.Experience{
  width: 46%;
}

.CircleIcon{
  width: 120px;
  height: 120px;
  background-color: #0097E6;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 60px;
}

.CircleIcon i{
  font-size: 60px;
  padding-top: 28px;
  color: white;
}

.TextContent{
  padding-top: 10px;
  text-align: center;
}

/* Contact Section */
.ContactContainer{
  margin-top: 200px;
  margin-bottom: 200px;
}

.ContactButtonOutside{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.ContactButton{
  text-align: center;
  color: white;
  padding: 30px 50px 30px 50px;
  background-color: #0097E6;
  border-radius: 30px;
  text-decoration: none;
  font-size: 25px;
  -webkit-transition: padding 0.5s; /* Safari */
  transition: padding 0.5s;
}

.ContactButton:hover{
  padding: 35px 55px 35px 55px;
}

/* Footer Section */
footer{
  width: 100%;
  background-color: #333333;
  padding: 20px 0px 20px 0px;
  color: white;
  text-align: center;
}

footer h4 a{
  color: white;
}


/* Blog Post Home Section */
.blogContainer{
  background-color: #333333;
  padding-top: 20px;
  margin-top: 20px;
  padding-bottom: 50px;
  min-height: 520px;
}

.blogTitle{
  text-align: center;
  color: white;
  font-size: 25px;
  margin-bottom: -25px;
}

.readMore a{
  color: white !important;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  padding-top: 6px;
  text-decoration: none;
  -webkit-transition: border-color 0.5s ease-in-out; /* Safari */
  transition: border-color 0.5s ease-in-out;
}

.readMore a:hover{
  border-color: black;
}

.allBlogs{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 25px;
}

.allBlogs a:hover{
  padding: 30px 30px 30px 30px;
}

.allBlogs a{
  text-decoration: none;
  color: white;
  background-color: #0097E6;
  padding: 20px 20px 20px 20px;
  width: 120px; 
  border-radius: 20px;
  -webkit-transition: padding 0.5s; /* Safari */
  transition: padding 0.5s;
}

.allBlogsTitle{
  text-align: center;
  font-size: 25px;
  margin-bottom: -25px;
}

/* Single Blog Post */

.blogPostContainer{
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 60px;
}

.singleBlogTitle{
  text-align: center;
  font-size: 35px;
  border-bottom: 2px solid rgba(0,0,0,0.5);
  padding-bottom: 10px;
}

.picture{
  text-align: center;
  height: 500px;
  margin: 10px auto 50px auto;
  border-radius: 20px;
  background-size: cover !important;
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  color: white;
  -webkit-transition: border-radius 0.2s; /* Safari */
  transition: border-radius 0.2s;
}

.dateOfBlog{
  font-size: 25px;
  padding-bottom: 10px;
}

.blogContent{
  margin-bottom: 40px;
}

.backButton{
  text-align: left;
  margin-top: 65px;
  width: 1260px;
  margin-left: auto;
  margin-right: auto;
}

.backButton a{
  text-decoration: none;
  color: white;
  background-color: #0097E6;
  padding: 20px 20px 20px 20px;
  width: 120px;
  border-radius: 20px;
  transition: padding 0.5s;
}

.backButton a:hover{
  padding: 20px 30px 20px 30px;
}


/* Timeline CSS */
.timelineTitleContainer h3{
  text-align: center;
  font-size: 25px;
  margin-bottom: 60px;
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #333333;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.timelineDivider{
  background-color: #0097E6;
  width: 15px;
  height: 15px;
  border-radius: 30px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.timeline h4{
  font-size: 20px;
}

.timeline .right{
  text-align: right;
  margin-top: 50px;
}

.middleDot{
  width: 20px;
  height: 20px;
  background-color: #0097E6;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  margin-top: -50px;
  border-radius: 10px;
}

@media only screen and (max-width: 1280px) {
  .results{
    width: 98%;
  }

  .SearchBox {
    width: 90%;
  }

  .inputContainer {
    width: 70%;
  }

  .HeaderContainer{
    width: 95%;
  }

  .result{
    width: 32%;
    margin: 0px 5px 50px 5px;
  }

  .TestimonialsCenter {
    width: 100%;
  }

  .ExpereinceContainer{
    width: 100%;
  }

  .PersonText{
    width: 80%;
  }

  .timeline::after {
    width: 0px;
  }

  .middleDot{
    width: 0px;
    height: 0px;
    margin-top: 5px;
  }

  .timeline .right{
    text-align: center;
    margin-top: 0px;
  }

  .timeline .left{
    text-align: center;

  }
}

@media only screen and (max-width: 950px) {
  .result{
    width: 48%;
  }

  .jobTitle {
    font-size: 30px;
  }

  .Popup{
    flex-direction: column;
    width: 98%;
  }

  .PopupSplit:first-child{
    display: none;
  }

  .PopupSplit:nth-child(2){
    width: 90%;
  }

  .Popup p {
    font-size: 12px;
  }

  .blogPostContainer{
    width: 95%;
  }
}

@media only screen and (max-width: 510px) {
  .AppHeader {
    height: 500px;
  }

  .ProgrammingLanguages {
    margin-top: 60px;
  }

  .TopBar {
    flex-direction: column;
  }

  .Introduction{
    padding-top: 20px;
    font-size: 20px;
  }

  .TopRight{
    justify-content: center;
  }

  .TopRight a:first-child { 
    padding-left: 0px;
  }

  .SearchInput {
    font-size: 16px;
    padding-left: 5px;
  }

  .inputContainer {
    width: 60%;
  }

  .fa-search {
    margin-left: 40px;
  }
  
  .results {
    flex-direction: column;
  }

  .result{
    width: 95%;
  }

  .Experience {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .ExpereinceContainer {
    flex-direction: column;
  }

  .timelineDivider{
    width: 90%;
    display: block;
    margin: 10px auto 10px auto;
  }
}